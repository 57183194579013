module.exports = [{
      plugin: require('/builds/clients/emes-consulting/emes-consulting.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-127676975-1","optimizeId":"UA-127676975-1","head":false,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"emes-consulting.com/"},
    },{
      plugin: require('/builds/clients/emes-consulting/emes-consulting.com/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#194255"},
    },{
      plugin: require('/builds/clients/emes-consulting/emes-consulting.com/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/clients/emes-consulting/emes-consulting.com/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
